<main id="main" #MainContentDiv>

  <app-header (academyMenus)="receiveAcademyMenuData($event)" [isIframe]="isIframe"></app-header>

  <!-- <div class="rvhap-site-mode-main">
    <span class="rvhap-site-mode-preview">
        <span class="rvhap-site-mode-label-preview">
            <fa-icon [icon]="['fas','info-circle']" class="rvhap-site-mode-icon"></fa-icon>
            <span>You are viewing article preview</span>
        </span>
    </span>
  </div> -->

  <router-outlet></router-outlet>
  <!-- <app-home></app-home> -->
  <button *ngIf="isShowScrollTopButton" class="scroll-to-top" (click)="scrollToTop()">
    <fa-icon [icon]="['fas','angle-up']"></fa-icon>
  </button>
  <app-footer [academyMenuData]="academyMenuData" [isIframe]="isIframe"></app-footer>

</main>