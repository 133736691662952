<section id="footer" class="footer bgcolor-footer">
  <div class="container-fluid">
    <div id="partner" aria-label="Partner section footer" class="partner-section-box footer-absolute"
      *ngIf="!partnersLoading;else partnersNotLoaded">
      <app-partners [pageloading]="partnersLoading"></app-partners>
    </div>
    <ng-template #partnersNotLoaded>
      <div id="partner" aria-label="Partner section footer" class="partner-section-box footer-absolute">
        <app-partners [pageloading]="partnersLoading"></app-partners>
      </div>
    </ng-template>

    <div class="footer-section">
      <div class="row">
        <!-- BJS Logo and Social Links -->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 pb-5">
          <img src="bjs_logo_white.svg" alt="">
          <ul class="pb-5 ps-0 d-sm-none d-md-block d-lg-block d-xl-none d-xxl-none">
            <li class="d-flex pt-5 text-white">
              <h4 class="footer-menu">Connect</h4>
              <hr class="small-hr">
            </li>
            <li>
              <div class="social-media pt-4">
                <a *ngFor="let media of socialMediaLink" class="navbar-brand navbar-brand-secondary-icons"
                  target="_blank" href="{{media.value}}" role="link" [attr.aria-label]="'visit link ' + media.value"
                  title="{{media.label}}">
                  <fa-icon aria-hidden="true" [icon]="media.icon"></fa-icon>
                </a>
              </div>
            </li>
          </ul>
        </div>

        <!-- Contact Section -->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 contact-footer custom-footer-padding">
          <div class="d-flex">
            <h4 class="footer-menu">Contact</h4>
            <hr>
          </div>
          <ul class="address-footer ps-0">
            <li>Salisbury House</li>
            <li>London Wall</li>
            <li>London, United Kingdom</li>
            <li>EC2M 5PS</li>
          </ul>
          <ul class="ps-0">
            <li><u> <a href="mailto:info@bjsacademy.com" class="text-white">info&#64;bjsacademy.com</a></u></li>
          </ul>
          <ul class="pb-5 ps-0 d-md-none d-sm-block d-lg-none d-xl-block d-xxl-block">
            <li class="d-flex pt-5">
              <h4 class="footer-menu">Connect</h4>
              <hr>
            </li>
            <li>
              <div class="social-media">
                <a *ngFor="let media of socialMediaLink" class="navbar-brand navbar-brand-secondary-icons"
                  target="_blank" href="{{media.value}}" role="link" [attr.aria-label]="'visit link ' + media.value"
                  title="{{media.label}}">
                  <fa-icon aria-hidden="true" [icon]="media.icon"></fa-icon>
                </a>
              </div>
            </li>
          </ul>
        </div>

        <!-- About Section with Updated Dropdowns -->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 contact-footer custom-footer-padding">
          <div class="d-flex">
            <h4 class="footer-menu">About</h4>
            <hr>
          </div>
          <ul class="ps-0 about-section">
            <li><a class="lonkContent" routerLink="/page/about">About BJS Foundation</a></li>
            <li>
              <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse" data-bs-target="#leadershipMenu"
                role="button" aria-expanded="false" aria-controls="leadershipMenu">
                Leadership
                <fa-icon class="ps-2" [icon]="isLeadershipMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
              </a>
              <ul id="leadershipMenu" class="collapse" (shown.bs.collapse)="isLeadershipMenuOpen = true"
                (hidden.bs.collapse)="isLeadershipMenuOpen = false">
                <li><a class="lonkContent" routerLink="/page/about/council-of-management">Council of Management</a></li>
                <li><a class="lonkContent" routerLink="/page/about/executive-committee">Executive Committee</a></li>
                <li><a class="lonkContent" routerLink="/page/about/sub-committees">Sub-committees</a></li>
                <li>
                  <a class="collapsed d-flex lonkContent" routerLink="/page/about/editorial-teams">
                    Editorial Teams
                  </a>
                </li>
                <li><a class="lonkContent" routerLink="/page/about/editorial-board">Editorial
                    Board</a></li>
                <li><a class="lonkContent" routerLink="/page/about/bjs-academy-team">BJS Academy team</a></li>
              </ul>
            </li>
            <!-- Foundation Partners -->
            <li>
              <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse" data-bs-target="#foundationPartnersMenu"
                role="button" aria-expanded="false" aria-controls="foundationPartnersMenu">
                BJS Foundation Partners
                <fa-icon class="ps-2" [icon]="isFoundationPartnersMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
              </a>
              <ul id="foundationPartnersMenu" class="collapse" (shown.bs.collapse)="isFoundationPartnersMenuOpen = true"
                (hidden.bs.collapse)="isFoundationPartnersMenuOpen = false">
                <li><a class="lonkContent" routerLink="/page/bjs-partners/strategic-partners-trainee">Strategic
                    Partners/Trainee</a></li>
                <li><a class="lonkContent" routerLink="/page/bjs-partners/strategic-partners">Strategic Partners</a>
                </li>
                <li><a class="lonkContent" routerLink="/page/bjs-partners/development-partners">Development Partners</a>
                </li>
                <li><a class="lonkContent" routerLink="/page/bjs-partners/partners-societies">Partner Societies</a></li>
                <li><a class="lonkContent" routerLink="/page/bjs-partners/educational-partners">Education Partners</a>
                </li>
                <li><a class="lonkContent" routerLink="/page/bjs-partners/industry-partners">Industry Partners</a></li>
              </ul>
            </li>

            <!-- Charitable Activities -->
            <li>
              <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse"
                data-bs-target="#charitableActivitiesMenu" role="button" aria-expanded="false"
                aria-controls="charitableActivitiesMenu">
                Charitable activities
                <fa-icon class="ps-2" [icon]="isCharitableActivitiesMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
              </a>
              <ul id="charitableActivitiesMenu" class="collapse"
                (shown.bs.collapse)="isCharitableActivitiesMenuOpen = true"
                (hidden.bs.collapse)="isCharitableActivitiesMenuOpen = false">
                <li><a class="lonkContent" routerLink="/page/about/editorial-apprenticeships">Editorial
                    apprenticeships</a></li>
              </ul>
            </li>
          </ul>
        </div>

        <!-- Company Section with Updated Dropdowns -->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 contact-footer custom-footer-padding">
          <div class="d-flex">
            <h4 class="footer-menu">Company</h4>
            <hr>
          </div>
          <ul class="ps-0 company-section">
            <li>
              <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse" data-bs-target="#BJSAcademyMenu"
                role="button" aria-expanded="false" aria-controls="BJSAcademyMenu">
                BJS Academy
                <fa-icon class="ps-2" [icon]="isBJSAcademyMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
              </a>
              <ul id="BJSAcademyMenu" class="collapse" (shown.bs.collapse)="isBJSAcademyMenuOpen = true"
                (hidden.bs.collapse)="isBJSAcademyMenuOpen = false">
                <li *ngIf="academyMenuData?.menu_name && academyMenuData.menu_name!=='BJS Academy'"><a class="lonkContent"
                    [routerLink]=" academyMenuData.url?'page/' + academyMenuData.url:''">{{academyMenuData.menu_name}}</a>
                </li>
                <li *ngIf="academyMenuData?.menu_name && academyMenuData.menu_name=='BJS Academy'"><a class="lonkContent"
                  [routerLink]=" academyMenuData.url? academyMenuData.url:''">{{academyMenuData.menu_name}}</a>
              </li>
                <li *ngFor="let menu of academyMenuData?.subMenus">
                  <a class="lonkContent" *ngIf="menu.subMenus.length==0"
                    [routerLink]="'page/' + menu.url? menu.url:''">{{menu.menu_name}}</a>
                  <a *ngIf="menu.subMenus.length>0" class="collapsed d-flex lonkContent" data-bs-toggle="collapse"
                    data-bs-target="#scientificSurgeryMenu" role="button" aria-expanded="false"
                    aria-controls="charitableActivitiesMenu">
                    {{menu.menu_name}}
                    <fa-icon class="ps-2" [icon]="isscientificSurgeryMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
                  </a>
                  <ul id="scientificSurgeryMenu" class="collapse"
                    (shown.bs.collapse)="isscientificSurgeryMenuOpen = true"
                    (hidden.bs.collapse)="isscientificSurgeryMenuOpen = false">
                    <li *ngFor="let submenu of menu.subMenus">
                      <a class="lonkContent" [routerLink]="submenu.url? submenu.url:''">{{submenu.menu_name}}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><a class="lonkContent" routerLink="/page/bjs-journals">BJS Journals</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-institute"> BJS Institute</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-award">BJS Award</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-partners">BJS Partners</a></li>
            <li><a class="lonkContent" (click)="openJoinModal()">Join BJS Academy</a></li>
            <li><a class="lonkContent" routerLink="/page/contact"> Contact</a></li>
            <li>
              <a class="collapsed d-flex lonkContent" data-bs-toggle="collapse" data-bs-target="#policyMenu"
                role="button" aria-expanded="false" aria-controls="leadershipMenu">
                Policy
                <fa-icon class="ps-2" [icon]="isPolicyMenuOpen ? 'caret-up' : 'caret-down'"></fa-icon>
              </a>
              <ul id="policyMenu" class="collapse" (shown.bs.collapse)="isPolicyMenuOpen = true"
                (hidden.bs.collapse)="isPolicyMenuOpen = false">
                <li><a class="lonkContent" routerLink="/page/terms-of-use">Terms of use</a></li>
                <li><a class="lonkContent" routerLink="/page/privacy-policy">Privacy policy</a></li>
                <li><a class="lonkContent" routerLink="/page/copyright-statement">Copyright statement</a></li>
                <li><a class="lonkContent" routerLink="/page/diversity-equity-and-inclusion-policy"><span>
                      Diversity, Equity and
                      Inclusion policy
                    </span></a></li>
                <li><a class="lonkContent" routerLink="/page/accessibility">Accessibility</a></li>
                <li><a class="lonkContent" routerLink="/page/cookies">Cookies</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<app-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-modal>