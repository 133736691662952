<main id="main" #MainContentDiv>

  <app-header (academyMenus)="receiveAcademyMenuData($event)"></app-header>

  <router-outlet></router-outlet>
  <!-- <app-home></app-home> -->
  <button *ngIf="isShowScrollTopButton" class="scroll-to-top" (click)="scrollToTop()">
    <fa-icon [icon]="['fas','angle-up']"></fa-icon>
  </button>
  <app-footer [academyMenuData]="academyMenuData"></app-footer>

</main>