import { Component, ElementRef, HostListener, ViewChild, PLATFORM_ID, Inject, ChangeDetectorRef, viewChildren, QueryList, ViewChildren, inject, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DropdownService } from '../../services/dropdown.service';
import { SharedService } from '../../services/shared.service';
import { MainHomeService } from '../../services/main-home.service';
import { ArticleService } from '../../services/article.service';
import { SearchContextService } from '../../services/search-context-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FilterServiceStateService } from '../../filter-service-state.service';
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss'
})


export class AppHeaderComponent implements OnInit, AfterViewInit {
  // @ViewChildren('megamenuAcademy') megamenuAcademy!: QueryList<ElementRef>;
  // @ViewChildren('dropdownMenuAcademy') dropdownMenus: QueryList<ElementRef>;
  // @ViewChild('megamenuPartners') megamenuPartners: ElementRef<HTMLElement> | undefined;
  // @ViewChild('dropdownMenuPartners') dropdownMenuPartners: ElementRef<HTMLElement> | undefined;
  // @ViewChild('megamenuAbout') megamenuAbout: ElementRef<HTMLElement> | undefined;
  // @ViewChild('dropdownMenuAbout') dropdownMenuAbout: ElementRef<HTMLElement> | undefined;
  // @ViewChild('dropdownMenuScientific') dropdownMenuScientific: ElementRef<HTMLElement> | undefined;
  @ViewChild('navbarContainer') navbarContainer!: ElementRef<HTMLElement>;
  @ViewChild('dropdownScientific') dropdownScientific: ElementRef<HTMLElement> | undefined;
  @ViewChild('joinBtn') joinBtn: ElementRef;
  @ViewChild('logoImage') logoImageTag: ElementRef;
  @ViewChild('navBar') navBar: ElementRef;
  @Output() academyMenus = new EventEmitter<any>();
  screenWidth: number;
  mobileView = false;
  isMenuOpen = false;
  logoImage = '';
  valueSet = false;
  menuOpenAcademy: boolean = false;
  menuOpenPartners: boolean = false;
  menuOpenAbout: boolean = false;
  menuOpenScientific = false;
  menus: any[] = [];
  menuStructure: any = {};
  categoryContent: any;
  isModalOpen = false;
  menuStates: { [key: string]: boolean } = {};
  isHomePage: boolean = false;
  menuHeadingLoading = true;
  menuContentLoading = true;
  loaderMenu = new Array(8).fill(0);
  private readonly searchContextService = inject(SearchContextService);
  url: string = '';
  urlArray: string[] = [];
  activeUrl: string = '';
  subMenuActive = false;
  clickedSubMenuParentName = '';
  megaMenuActive: boolean;
  screenHeight = 0;
  isPreviewMode: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private cdr: ChangeDetectorRef,
    private sharedService: SharedService, private el: ElementRef, private dropdownService: DropdownService,
    private mainService: MainHomeService, private articleService: ArticleService, public router: Router, private route: ActivatedRoute,
    private renderer: Renderer2,private filterService: FilterServiceStateService
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
      setTimeout(() => {
        document.documentElement.style.setProperty('--screenWidth', `${this.screenWidth}px`);
        document.documentElement.style.setProperty('--screenHeight', `${this.screenHeight}px`);
        document.documentElement.style.setProperty('--navBarHeight', `${this.navbarContainer?.nativeElement.offsetHeight}px`);
      }, 500);
    }
  }
  ngOnInit() {

 
    if (isPlatformBrowser(this.platformId)) {
      this.checkResponsive();
      window.addEventListener('scroll', this.onScroll.bind(this));
      this.sharedService.getAllThemsettingsData().subscribe(response => {
        this.logoImage = response.data.journal_logo;
        this.valueSet = true;
      })

    }
    this.getMenuDetails();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url
        this.isHomePage = this.url === '/';
        this.urlArray = event.urlAfterRedirects.split('/');

        for (const item of this.menus) {
          const pattern = new RegExp(item.identifier.replace(/_/g, '-'));
          if (pattern.test(this.urlArray[1]) || pattern.test(this.urlArray[2])) {
            this.clickedSubMenuParentName = item.identifier;
            this.menuStates[item.identifier] ? this.closeMenu(item.identifier) : null;
            break;
          }
        }
        if (isPlatformBrowser(this.platformId)) {
          this.menus.forEach(e => {
            if (e.identifier === this.clickedSubMenuParentName || this.isHomePage) {
              const menuColor = e?.menu_color || '#eb1930';  // Use default if undefined
              document.documentElement.style.setProperty('--menu-color', menuColor);
            }
          });
        }
      }
    });
    if (this.joinBtn && this.joinBtn.nativeElement) {
      if (this.screenWidth > 992) {
        this.joinBtn.nativeElement.style.display = 'block';
      } else {
        this.joinBtn.nativeElement.style.display = 'none';
      }
    }

  
  }

  // onScroll() {
  //   const logo = document.querySelector('#logo');
  //   if (window.scrollY > 0) {
  //     this.navbarContainer.nativeElement.classList.add('scroll-shadow');
  //     this.navbarContainer.nativeElement.classList.add('sticky-container');

  //     if (this.isHomePage && window.scrollY > 637) {

  //       this.joinBtn.nativeElement.style.display = 'none';
  //       logo.setAttribute('style', ' bottom:-2px; transition: height 0.3s ease;');

  //     } else if (!this.isHomePage) {

  //       this.joinBtn.nativeElement.style.display = 'none';
  //       logo.setAttribute('style', ' bottom:-2px; transition: height 0.3s ease;');
  //       this.navbarContainer.nativeElement.style.height = 'auto';
  //       // this.navbarContainer.nativeElement.style.zIndex='2';
  //     }

  //   } else {
  //     //  if(this.screenWidth>992) this.joinBtn.nativeElement.style.display = 'block';
  //     this.joinBtn.nativeElement.style.display = 'block';
  //     logo.setAttribute('style', this.screenWidth > 992 ? 'bottom:22px' : 'bottom:0px');
  //     this.navbarContainer.nativeElement.classList.remove('scroll-shadow');
  //     this.navbarContainer.nativeElement.classList.remove('sticky-container');

  //   }
  //   // if(this.screenWidth>992) this.joinBtn.nativeElement.style.display = 'block';
  //   // else this.joinBtn.nativeElement.style.display = 'none !important';

  // }
  onScroll() {
    const logo = document.querySelector('#logo');
    if (window.scrollY > 0) {
      this.navbarContainer.nativeElement.classList.add('scroll-shadow');
      this.navbarContainer.nativeElement.classList.add('sticky-container');
  
      // Set z-index to 2 when scrolled

      this.filterService.getLoadFilterState().subscribe((isFilterOpen) => {
        this.navbarContainer.nativeElement.style.zIndex = isFilterOpen ? '1' : '2';
      });  
      if (this.isHomePage && window.scrollY > 637) {
        this.joinBtn.nativeElement.style.display = 'none';
        logo.setAttribute('style', 'bottom:-2px; transition: height 0.3s ease;');
      } else if (!this.isHomePage) {
        this.joinBtn.nativeElement.style.display = 'none';
        logo.setAttribute('style', 'bottom:-2px; transition: height 0.3s ease;');
        this.navbarContainer.nativeElement.style.height = 'auto';
      }
  
    } else {
      // Reset join button visibility
      this.joinBtn.nativeElement.style.display = 'block';
      logo.setAttribute('style', this.screenWidth > 992 ? 'bottom:22px' : 'bottom:0px');
  
      // Remove sticky styles
      this.navbarContainer.nativeElement.classList.remove('scroll-shadow');
      this.navbarContainer.nativeElement.classList.remove('sticky-container');
  
      // Remove z-index when not scrolled
      this.navbarContainer.nativeElement.style.zIndex = 'initial'; // or 'auto' based on your needs
    }
  }
  
  closeMenu(menu: string) {
    this.menuStates[menu] = false;
    // this.resetSearchContext();
    setTimeout(() => {
      this.dropdownService.closeMenu(menu);
    }, 200);


  }

  cancelCloseMenu(menu: string) {
    // this.menuHeadingLoading=true;
    // this.menuContentLoading=true;
    this.menuStates[menu] = true;
    this.dropdownService.cancelCloseMenu(menu);
  }
  toggleMobileView() {
    this.isMenuOpen = !this.isMenuOpen;
    this.mobileView = !this.mobileView;
    if (!this.isMenuOpen) {
      this.menus.forEach(e => {
        this.menuStates[e.identifier] = false;
      });
    }
    if (this.mobileView) {
      this.renderer.listen('window', 'click', this.detectOutsideClick.bind(this));
    }
    
  }

  openMenu(menu: string) {
    // this.menuHeadingLoading=true;
    // this.menuContentLoading=true;
    // this.closeOtherMenu(menu);
    if (!this.menuStates[menu]) this.menuStates[menu] = true;
    this.dropdownService.openMenu(menu);
    // this.getCategoryContent(menu);


  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
      this.checkResponsive();
      if (this.screenWidth >= 992 && this.isMenuOpen) {
        this.mobileView = false;
        this.isMenuOpen=false;
      }
      if (this.screenWidth > 992) this.joinBtn.nativeElement.style.display = 'block !important';
      else this.joinBtn.nativeElement.style.display = 'none !important';
        document.documentElement.style.setProperty('--screenWidth', `${this.screenWidth}px`);
        document.documentElement.style.setProperty('--screenHeight', `${this.screenHeight}px`);
        document.documentElement.style.setProperty('--navBarHeight', `${this.navbarContainer?.nativeElement.offsetHeight}px`);
    }
  }

  checkResponsive() {
    if (this.screenWidth <= 992) {
    } else {
      this.mobileView = false;
    }
  }
  // toggleMenu(menuType: string) {
  //   if (menuType === 'academy') {
  //     this.menuOpenAcademy = !this.menuOpenAcademy;
  //     if (this.menuOpenAcademy) {
  //       this.menuOpenScientific = false;
  //     }
  //   } else if (menuType === 'scientific') {
  //     this.menuOpenScientific = !this.menuOpenScientific;
  //   } else if (menuType === 'about') {
  //     this.menuOpenAbout = !this.menuOpenAbout;
  //   }
  //   this.updateDropdown(this.dropdownMenuAcademy, this.menuOpenAcademy);
  //   // this.updateDropdown(this.dropdownMenuScientific, this.menuOpenScientific);
  //   // this.updateDropdown(this.dropdownMenuAbout, this.menuOpenAbout);
  // }

  // private updateDropdown(element: ElementRef | undefined, isOpen: boolean) {
  //   if (element) {
  //     const nativeElement = element.nativeElement;
  //     if (isOpen) {

  //       this.renderer.addClass(nativeElement, 'show');
  //     } else {
  //       this.renderer.removeClass(nativeElement, 'show');
  //     }
  //   }
  // }
  // isMenuOpenMenu(menu: string): boolean {
  //   return this.dropdownService.isOpen(menu);

  // }
  // cancelCloseMenu(menu: string) {
  //   this.dropdownService.cancelCloseMenu(menu);
  // }
  isMenuOpenMenu(menu: string): boolean {
    return this.dropdownService.isOpen(menu);
  }
  toggleMenu(identifier: string) {
    this.menuStates[identifier] = !this.menuStates[identifier];

  }

  isMenuOpenfunction(identifier: string): boolean {
    return !!this.menuStates[identifier];
  }

  getMenuDetails() {
    this.mainService.getMenuDetails().subscribe((response: any) => {
      this.menus = response;
      this.menus.forEach(e => {
        if (e.identifier == 'bjs-academy') {
          this.academyMenus.emit(e);
        }
        this.menuStates[e.identifier] = false;
        if (e.subMenus && e.subMenus.length > 0) {
          const data = {
            category: e.identifier
          }
          this.articleService.getCategoryContent(data).subscribe((categoryResponse: any) => {


            this.categoryContent = categoryResponse.data[0];
            e['categoryContent'] = this.categoryContent;

            this.menuHeadingLoading = false;
            this.menuContentLoading = false;
          });
        }
      });
    });
  }


  sortMenu(menu: any) {
    // Sort the menu items and their submenus based on the sort_order
    menu.subMenus.sort((a: any, b: any) => a.sort_order - b.sort_order);
    menu.subMenus.forEach((subMenu: any) => this.sortMenu(subMenu));
  }

  // getCategoryContent(category: string) {
  //   this.menuHeadingLoading = true;
  //   this.menuContentLoading = true;
  //   const data = {
  //     category: category
  //   }
  //   this.articleService.getCategoryContent(data).subscribe((response: any) => {
  //     this.categoryContent = response.data[0];

  //     this.menuHeadingLoading = false;
  //     this.menuContentLoading = false;
  //   });

  // }

  closeOtherMenu(menu: string) {
    // this.menuHeadingLoading=true;
    // this.menuContentLoading=true;
    for (let key in this.menuStates) {
      this.menuStates[key] = key === menu;
    }
  }
  logNavigation() {
    this.router.navigate(['/bjs-academy', 'scientific-surgery', 'randomized-clinical-trials']).then(success => {
    }).catch(error => {
    });
  }
  // getMenuValue(eachMenu: any) {
  //   this.getCategoryContent(eachMenu)

  // }


  openJoinModal() {
    this.isModalOpen = true;
  }

  closeJoinModal() {
    this.isModalOpen = false;
  }
  private resetSearchContext(): void {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.setSearchContent(undefined);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.documentElement.style.setProperty('--screenWidth', `${this.screenWidth}px`);
      document.documentElement.style.setProperty('--screenHeight', `${this.screenHeight}px`);
      document.documentElement.style.setProperty('--navBarHeight', `${this.navbarContainer?.nativeElement.offsetHeight}px`);
    }
    this.cdr.detectChanges();
  }

  getClickedSubMenuParent(menu: any) {
    if (menu.url !== null) this.clickedSubMenuParentName = menu.identifier;
  }
  detectOutsideClick(event: MouseEvent) {
    const clickedInside = this.el.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.mobileView = false;
      this.isMenuOpen=false;
    }
  }

}
