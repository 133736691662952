import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { socilMediaLinks } from '../../interfaces/socialMedia';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.scss'
})
export class AppFooterComponent implements OnInit {
  @Input() academyMenuData:any={}
  @Input() isIframe:boolean;

  subscription: Subscription = new Subscription;
  socialMediaLink: socilMediaLinks[] = [];
  partnersLoading = false;
  isModalOpen = false;
  isLeadershipMenuOpen = false;
  isFoundationPartnersMenuOpen = false;
  isCharitableActivitiesMenuOpen = false;
  isPolicyMenuOpen=false;
  isBJSAcademyMenuOpen=false;
  isscientificSurgeryMenuOpen=false;
  iseditorialTeamMenuOpen=false;
  
  constructor(private sharedService: SharedService) {

  }
  ngOnInit(): void {
    if(!this.isIframe){

    this.subscription = this.sharedService.getAllThemsettingsData().subscribe(response => {
      const filterderData = response ? response.data.social_media_links.filter((link: socilMediaLinks) => link.value !== null) : null
      this.socialMediaLink = filterderData;
    })
    setTimeout(() => {
      this.partnersLoading = false;
      
    }, 500);
  }

  }

  openJoinModal() {
    this.isModalOpen = true;
  }

  closeJoinModal() {
    this.isModalOpen = false;
  }
  ngOnDestroy(): void {
    // Unsubscribe from router events to prevent memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
