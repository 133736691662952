<div class="row">
    <div class="col-12">
        <p class="partner-heading" *ngIf="!pageloading;else contentNotLoaded">Thank you to our strategic partners</p>
        <ng-template #contentNotLoaded>
            <div class="row">
                <div class="col-12 d-flex jusitfy-content-center">
                    <p class="partner-heading skeleton skeleton-text skeleton-heading"></p>
                </div>
            </div>

        </ng-template>
    </div>
</div>
<div class="container text-center">
    <div class="row justify-content-center" *ngIf="!pageloading;else contentImageNotLoaded">
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3">
            <a routerLink="page/bjs-partners/strategic-partners/european-hernia-society" class="partners-logo-link">
                <img src="partners/EHS-LOGO-DEF-05-002-scaled-e1687512768741.jpg" alt="European Hernia Society"
                    class="img-fluid logo">
            </a>

        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
            <a routerLink="page/bjs-partners/strategic-partners/swedish-surgical-society" class="partners-logo-link">
                <img src="partners/swedish-surgical-society.png" alt="Swedish Surgical Society" class="img-fluid logo">
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 ">
            <a routerLink="page/bjs-partners/strategic-partners/sociedad-espanola-de-investigaciones-quirurgicas" class="partners-logo-link">
                <img src="partners/seiq.png" alt="Sociedad Española de Investigaciones Quirúrgicas"
                    class="img-fluid logo">
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
            <a routerLink="page/bjs-partners/strategic-partners/swiss-society-of-surgery" class="partners-logo-link">
                <img src="partners/swiss-society-of-surgery.png" alt="Swiss Society of Surgery" class="img-fluid logo">
            </a>

        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
            <a routerLink="page/bjs-partners/strategic-partners/acta-chirurgica-scandinavica" class="partners-logo-link">
                <img src="partners/acta.png" alt="Acta Chirurgica Scandinavica" class="img-fluid logo">
            </a>
        </div>

        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
            <a routerLink="page/bjs-partners/strategic-partners/association-of-surgeons-of-great-britain-ireland" class="partners-logo-link">
                <img src="partners/asgbi.png" alt="Association of Surgeons of Great Britain & Ireland"
                class="img-fluid logo">
            </a>
           
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
            <a routerLink="page/bjs-partners/strategic-partners/the-association-of-surgeons-in-training" class="partners-logo-link">
                <img src="partners/asit.png" alt="The Association of Surgeons in Training" class="img-fluid logo">
            </a>
            
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
            <a  routerLink="page/bjs-partners/strategic-partners/surgical-research-society" class="partners-logo-link">
                <img src="partners/srs.png" alt="Surgical Research Society" class="img-fluid logo">
            </a>
           
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
           <a routerLink="page/bjs-partners/strategic-partners/sis" class="partners-logo-link">
            <img src="partners/SIS_E_logo.jpg" alt="Sis" class="img-fluid logo w-50">
           </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
            <a routerLink="page/bjs-partners/strategic-partners/european-society-of-endocrine-surgeons">
                <img src="partners/eses.png" alt="European Society of Endocrine Surgeons" class="img-fluid logo">
            </a>
            
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
           <a  routerLink="page/bjs-partners/strategic-partners/student-audit-and-research-in-surgery" class="partners-logo-link">
            <img src="partners/STARSurg-e1678096555882.jpg" alt="Student Audit and Research in Surgery"
            class="img-fluid logo">
           </a>
        </div>
    </div>
    <ng-template #contentImageNotLoaded>
        <div class="row justify-content-center">
            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 " *ngFor="let each of ImageArray; let ind=index"
                [ngClass]="ind==1?'col-xxl-3':'col-xxl-2'">
                <div class="img-fluid logo skeleton skeleton-img mt-3"> &nbsp;</div>
            </div>
        </div>
    </ng-template>
</div>