<div class="container-fluid custom-main-padding  " #navbarContainer>
  <!-- <div *ngIf="isPreviewMode" class="rvhap-site-mode-main pt-5">
    <span class="rvhap-site-mode-preview join-btn">
      <span class="rvhap-site-mode-label-preview">
        <fa-icon [icon]="['fas','info-circle']" class="rvhap-site-mode-icon"></fa-icon>
        <span>You are viewing preview</span>
      </span>
    </span>
  </div> -->
  
  <div class="join-btn" #joinBtn *ngIf="!isPreviewMode">
    <!-- <a href="#" class="btn btn--join">Join BJS Academy</a> -->
    <a href="#" class="btn btn--join" (click)="openJoinModal()">Join BJS Academy</a>

  </div>
  <nav class="navbar navbar-expand-lg navbar-fixed-top p-0">
    <div class="container-fluid pe-0 ps-0">
      <div class="navbar-brand pl-5 pr-5">
        <img id="logo" src="{{logoImage}}" routerLink="/" *ngIf="valueSet" class="logo-image" alt="" width="200"
          height="55">
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        (click)="toggleMobileView()">
        <span *ngIf="!isMenuOpen" class="navbar-toggler-icon">

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">

            <path d="M3 6h18M3 12h18M3 18h18" />
          </svg>
        </span>
        <span *ngIf="isMenuOpen">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7a.996.996 0 1 0-1.41 1.41L10.59 12l-4.88 4.88a.996.996 0 1 0 1.41 1.41L12 13.41l4.88 4.88a.996.996 0 1 0 1.41-1.41L13.41 12l4.88-4.88a.996.996 0 0 0 0-1.41z" />
          </svg>
        </span>
      </button>

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-5 mb-2 mb-lg-0 custom-navbar">
          <li class="nav-item" *ngFor="let eachMenu of menus ;let index=index"
            [ngClass]="{ 'dropdown megamenu': eachMenu.subMenus.length > 0 }"
            (mouseenter)="openMenu(eachMenu.identifier);">
            <div *ngIf="eachMenu.subMenus.length === 0; else elseSubmenus">
              <a class="nav-link" aria-current="page" [routerLink]="eachMenu.url"
                routerLinkActive="nav-link-active-link" [routerLinkActiveOptions]="{exact: true}"
                (click)="getClickedSubMenuParent(eachMenu)"
                [ngClass]="{'nav-link-active-link': router.isActive(eachMenu.url, true) }"
                (mouseenter)="closeOtherMenu(eachMenu.identifier)" (mouseleave)="closeMenu(eachMenu.identifier)">{{
                eachMenu.menu_name }} </a>
            </div>

            <ng-template #elseSubmenus>
              <a id="dropdownMenuButton" (mouseenter)="cancelCloseMenu(eachMenu.identifier)"
                (mouseleave)="closeMenu(eachMenu.identifier)" routerLinkActive="nav-link-active-link"
                [routerLinkActiveOptions]="{exact: true}" class="nav-link dropdown-toggle font-weight-bold"
                (click)="getClickedSubMenuParent(eachMenu);"
                [ngStyle]="{
                  'border-bottom': menuStates[eachMenu.identifier] || megaMenuActive || clickedSubMenuParentName == eachMenu.identifier 
                    ? '3px solid var(--menu-color)' 
                    : '3px solid transparent'
                }"
                [routerLink]="eachMenu.url">
                <!-- [ngStyle]="{'border-bottom': router.isActive(eachMenu.url, true) || isSubmenuActive(eachMenu.subMenus)?'3px solid #EB1930':'none'}" -->

                {{ eachMenu.menu_name }}
                <span class="custom-icon"
                  [ngStyle]="{ 'transform': menuStates[eachMenu.identifier] ? 'rotate(180deg)' : 'rotate(0deg)' }"></span>
              </a>
              <div class="dropdown-menu border-0 p-0 m-0" [class.show]="menuStates[eachMenu.identifier]"
                (mouseenter)="cancelCloseMenu(eachMenu.identifier)" (mouseleave)="closeMenu(eachMenu.identifier)">
                <!-- Your submenu content -->
                <div class="custom-megamenu-color row">
                  <div class="col-sm-6">
                    <div class="w-full megamenu-partner">
                      <h2 *ngIf="!menuHeadingLoading && eachMenu.categoryContent">{{ eachMenu.categoryContent.heading }}
                      </h2>
                      <h2 *ngIf="menuHeadingLoading " class="skeleton ">&nbsp;&nbsp;</h2>
                      <hr class="hr hr--color hr--lg">
                      <div class="section__copy" *ngIf="!menuContentLoading && eachMenu.categoryContent">
                        <div [innerHtml]="eachMenu.categoryContent.primarycontent "></div>
                      </div>
                      <div class="section__copy" *ngIf="menuContentLoading">
                        <p class="skeleton skeleton-text "></p>
                        <p class="skeleton skeleton-text "></p>
                        <p class="skeleton skeleton-text "></p>
                        <p class="skeleton skeleton-text skeleton-text-80"></p>
                      </div>
                      <a *ngIf="!menuContentLoading && eachMenu.categoryContent && eachMenu.identifier =='bjs-academy'"
                        [routerLink]="eachMenu.identifier" class="btn btn--white btn--arrow"  (click)="closeMenu(eachMenu.identifier)">
                        <span class="btn__span">Discover the {{eachMenu.menu_name}}</span>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.86" height="14.599"
                            viewBox="0 0 6.86 11.599">
                            <g id="Group_9528" transform="translate(-148.18 -3798.39)">
                              <path id="Path_10431" d="M0,5.269,5.269,0l5.27,5.269"
                                transform="translate(153.979 3798.92) rotate(90)" fill="currentColor"></path>
                            </g>
                          </svg>
                        </div>
                      </a>
                      <a *ngIf="!menuContentLoading && eachMenu.categoryContent && eachMenu.menu_name =='BJS Partners'"  (click)="closeMenu(eachMenu.identifier)"
                        routerLink="page/bjs-partners/strategic-partners" class="btn btn--white btn--arrow">
                        <span class="btn__span">Our {{eachMenu.menu_name}}</span>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.86" height="14.599"
                            viewBox="0 0 6.86 11.599">
                            <g id="Group_9528" transform="translate(-148.18 -3798.39)">
                              <path id="Path_10431" d="M0,5.269,5.269,0l5.27,5.269"
                                transform="translate(153.979 3798.92) rotate(90)" fill="currentColor"></path>
                            </g>
                          </svg>
                        </div>
                      </a>
                      <a *ngIf="!menuContentLoading && eachMenu.categoryContent && eachMenu.menu_name =='About'"
                      (click)="closeMenu(eachMenu.identifier)"
                        routerLink="page/about" class="btn btn--white btn--arrow">
                        <span class="btn__span">Find out more {{eachMenu.identifier}} us</span>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.86" height="14.599"
                            viewBox="0 0 6.86 11.599">
                            <g id="Group_9528" transform="translate(-148.18 -3798.39)">
                              <path id="Path_10431" d="M0,5.269,5.269,0l5.27,5.269"
                                transform="translate(153.979 3798.92) rotate(90)" fill="currentColor"></path>
                            </g>
                          </svg>
                        </div>
                      </a>
                      <a *ngIf="menuContentLoading " class="btn btn--white btn--arrow">
                        <span class="btn__span skeleton" *ngIf="menuContentLoading "> &nbsp; &nbsp; &nbsp; &nbsp;</span>

                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9.86" height="14.599"
                            viewBox="0 0 6.86 11.599">
                            <g id="Group_9528" transform="translate(-148.18 -3798.39)">
                              <path id="Path_10431" d="M0,5.269,5.269,0l5.27,5.269"
                                transform="translate(153.979 3798.92) rotate(90)" fill="currentColor"></path>
                            </g>
                          </svg>
                        </div>
                      </a>

                    </div>
                  </div>


                  <div class="col-sm-6 megamenu-partner">
                    <div class="row">
                      <div *ngIf="!menuContentLoading"
                        [ngClass]="{ 'col-sm-12': eachMenu.subMenus.length < 4, 'col-sm-6': eachMenu.subMenus.length > 4}">
                        <ul>
                          <li
                            *ngFor="let eachsubmenus of eachMenu.subMenus.slice(0, 4)"
                            class="nav-link-partner" (click)="closeMenu(eachMenu.menu_name)">
                            <div *ngIf="eachsubmenus.subMenus.length === 0; else menudata" class="each-menu">
                              <!-- <fa-icon [icon]="['fas','caret-right']" class="me-2"></fa-icon> -->
                              <a class="nav-link-partner sub-menu-color"
                                [routerLink]="eachsubmenus.url!=null?eachsubmenus.url:null"
                                (click)="closeMenu(eachMenu.identifier);getClickedSubMenuParent(eachMenu);"> {{
                                eachsubmenus.menu_name }}</a>
                              <hr class="hr hr--color hr--lg">

                            </div>
                            <ng-template #menudata>
                              <a class="nav-link-partner sub-menu-color"
                                [routerLink]="eachsubmenus.url!=null?eachsubmenus.url:null"
                                (click)="closeMenu(eachMenu.identifier);getClickedSubMenuParent(eachMenu);"> {{
                                eachsubmenus.menu_name }}</a>
                              <hr class="hr hr--color hr--lg">
                              <ul *ngIf="eachsubmenus.subMenus.length > 0" class="ms-2">
                                <li *ngFor="let subsubmenu of eachsubmenus.subMenus" class="nav-link-partner "
                                  (click)="closeMenu(eachMenu.menu_name)">
                                  <!-- <fa-icon [icon]="['fas','caret-down']" class="me-2"></fa-icon> -->
                                  <a class="nav-link-custom sub-menu-color" [routerLink]="subsubmenu.url"
                                    (click)="closeMenu(eachMenu.identifier) ;getClickedSubMenuParent(eachMenu); ">
                                    {{subsubmenu.menu_name}}
                                  </a>
                                </li>
                              </ul>
                            </ng-template>

                          </li>
                        </ul>
                      </div>

                      <div *ngIf="!menuContentLoading"
                        [ngClass]="{ 'col-sm-12': eachMenu.subMenus.length < 4, 'col-sm-6': eachMenu.subMenus.length >= 4 }">
                        <!-- <ul>
                            <li *ngFor="let eachsubmenus of eachMenu.subMenus.slice(4)" class="nav-link-partner each-menu" 
                              (click)="closeMenu(eachMenu.menu_name)" >
                              <fa-icon [icon]="['fas','caret-right']" class="me-2"></fa-icon>
                              <a class="nav-link-partner each-menu"  [routerLink]="eachsubmenus.url">
                                {{eachsubmenus.menu_name }} 
                                <ul *ngIf="eachsubmenus.subMenus.length > 0">
                                  <li *ngFor="let subsubmenu of eachsubmenus.subMenus" class="nav-link-partner"
                                    (click)="closeMenu(eachMenu.menu_name)">
                                    <a class="nav-link-custom" [routerLink]="subsubmenu.url+'/page/1'">
                                      <fa-icon [icon]="['fas','caret-right']" class="me-2"></fa-icon>
                                      {{ subsubmenu.menu_name }}
                                    </a>
                                  </li>
                                </ul>
                              </a>
                            </li>
                          </ul> -->
                        <ul>
                          <li *ngFor="let eachsubmenus of eachMenu.subMenus.slice(4)" class="nav-link-partner each-menu"
                            (click)="closeMenu(eachMenu.menu_name)">
                            <!-- <fa-icon *ngIf="eachsubmenus.subMenus.length == 0" [icon]="['fas','caret-right']" class="me-2"></fa-icon> -->
                            <!-- <fa-icon *ngIf="eachsubmenus.subMenus.length > 0" [icon]="['fas','caret-down']" class="me-2"></fa-icon> -->
                            <a class="nav-link-partner each-menu sub-menu-color"
                              [routerLink]="eachsubmenus.url!=null?eachsubmenus.url:null"
                              (click)="closeMenu(eachMenu.identifier);getClickedSubMenuParent(eachMenu);">
                              {{eachsubmenus.menu_name}}
                            </a>
                            <hr class="hr hr--color hr--lg">
                            <!-- Nested submenus -->
                            <ul *ngIf="eachsubmenus.subMenus.length > 0" class="ms-2">
                              <li *ngFor="let subsubmenu of eachsubmenus.subMenus" class="nav-link-partner "
                                (click)="closeMenu(eachMenu.menu_name)">
                                <!-- <fa-icon [icon]="['fas','caret-down']" class="me-2"></fa-icon> -->
                                <a class="nav-link-custom sub-menu-color" [routerLink]="subsubmenu.url"
                                  (click)="closeMenu(eachMenu.identifier) ;getClickedSubMenuParent(eachMenu); ">
                                  {{subsubmenu.menu_name}}
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ul>

                      </div>
                      <div *ngIf="menuContentLoading"
                        [ngClass]="{ 'col-sm-12': loaderMenu.length < 4, 'col-sm-6': loaderMenu.length >= 4 }">
                        <ul>
                          <li *ngFor="let eachsubmenus of loaderMenu.slice(0, 4)" class="nav-link-partner">
                            <div class="each-menu">
                              <fa-icon [icon]="['fas','caret-right']" class="me-2"></fa-icon>
                              <a class="nav-link-partner  m-2">
                                <span class="nav-link-partner-loader skeleton skeleton-text">
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp;

                                </span>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>

        </ul>
      </div>

      <div *ngIf="mobileView" class="collapse navbar-collapse nav-mobile nav-item-mobile scroll-menu" id="navbarSupportedContent"   >
        <ul class="navbar-nav custom-navbar-mobile">
          <li class="nav-item nav-item-mobile" *ngFor="let eachMenu of menus"
            [ngClass]="{ 'dropdown mobile-dropdown': eachMenu.subMenus.length > 0 }">

            <!-- Check if the menu has subMenus -->
            <div *ngIf="eachMenu.subMenus.length == 0; else elseSubmenusMobileView">
              <a class="sub" aria-current="page" routerLink="{{eachMenu.url}}"
                (click)="toggleMobileView();">{{eachMenu.menu_name}}</a>
            </div>

            <ng-template #elseSubmenusMobileView>
              <a class="nav-link dropdown-toggle sub" role="button" (click)="toggleMenu(eachMenu.identifier);"
                [attr.aria-expanded]="menuOpenAcademy ? 'true' : 'false'" 
              >
                {{eachMenu.menu_name}}
                <span class="custom-icon" 
                  [ngStyle]="{ 'transform': menuStates[eachMenu.identifier] ? 'rotate(180deg)' : 'rotate(0deg)' }"></span>

              </a>

              <!-- Dropdown for Submenus -->
              <ul class="dropdown-menu custom-border academy-dropdown"
                [ngClass]="{'show': isMenuOpenfunction(eachMenu.identifier)}">
                <li  class="nav-item nav-item-mobile" *ngIf="eachMenu.url!==null">
                  <a class="dropdown-item" aria-current="page" routerLink="{{eachMenu.url}}"
                  (click)="toggleMobileView();">{{eachMenu.menu_name}}</a>
                </li>
                <li class="nav-item nav-item-mobile" *ngFor="let eachSubMenu of eachMenu.subMenus">

                  <!-- Check if the subMenu has subMenus -->
                  <a class="dropdown-item" aria-current="page" routerLink="{{eachSubMenu.url}}"
                    (click)="toggleMobileView();" *ngIf="eachSubMenu.subMenus.length == 0; else elsemenusMobileView">
                    {{eachSubMenu.menu_name}}
                  </a>

                  <ng-template #elsemenusMobileView>
                    <a class="nav-link dropdown-toggle toggle-left pt-3"role="button" 
                      [attr.aria-expanded]="menuOpenScientific ? 'true' : 'false'" (click)="toggleMenu(eachSubMenu.identifier)">
                      {{eachSubMenu.menu_name}}
                      <span class="custom-icon" 
                        [ngStyle]="{ 'transform': menuStates[eachSubMenu.identifier] ? 'rotate(180deg)' : 'rotate(0deg)' }"></span>
                    </a>

                    <!-- Nested Dropdown for deeper subMenus -->
                    <ul class="dropdown-menu scientific-dropdown"
                      [ngClass]="{'show': isMenuOpenfunction(eachSubMenu.identifier)}"> <!-- [ngStyle]="{ 'width': screenWidth+ 'px' }" -->
                      <li class="nav-item nav-item-mobile" *ngIf="eachSubMenu.url!==null" >
                        <a class="dropdown-item toggle-left" routerLink="{{eachSubMenu.url}}"
                          (click)="toggleMobileView();">
                          {{eachSubMenu.menu_name}}

                        </a>
                      </li>
                      <li class="nav-item nav-item-mobile" *ngFor="let submenu of eachSubMenu.subMenus">
                        <a class="dropdown-item toggle-left" [routerLink]="submenu.url"
                          (click)="toggleMobileView();">
                          {{submenu.menu_name}}

                        </a>
                      </li>
                    </ul>
                  </ng-template>

                </li>
              </ul>
            </ng-template>

          </li>
          <li class="nav-item nav-item-mobile" >
            <a class="sub" routerLink="/bjs-academy/all-articles/page/1" (click)="toggleMobileView();"> <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>  Search</a>
          </li>
          <li class="nav-item nav-item-mobile"(click)="openJoinModal() ;toggleMobileView();">
            <a class="sub">Join BJS Academy</a>
          </li>

        </ul>

      </div>

    </div>
  </nav>
</div>
<app-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-modal>