import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MainHomeService } from './services/main-home.service';
import { isPlatformBrowser } from '@angular/common';
import { JournalService } from './services/journal.service';
import { Subscription } from 'rxjs';
import { SharedService } from './services/shared.service';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  @ViewChild('MainContentDiv') MainContentDiv: ElementRef<HTMLElement>
  title = 'site';
  menuVisible: boolean = false;
  isShowScrollTopButton = false;
  private showScrollHeight = 400;
  subscription: Subscription[] = [];
  academyMenuData: any;
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  constructor(private library: FaIconLibrary,
    private sharedService: SharedService,
    private mainService: MainHomeService,
    @Inject(PLATFORM_ID) private platformId: any,
    private ccService: NgcCookieConsentService,
    private journalService: JournalService,) {
    // Add all icons to the library
    this.library.addIconPacks(fas, far, fab);
  }
  ngOnInit(): void {
    // if (!this.ccService.hasConsented()) {
      // console.log('Cookie consent has already been accepted');

      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
        () => {
       

          // you can use this.ccService.getConfig() to do stuff...
        });

      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {
          // console.log("frve");
          // you can use this.ccService.getConfig() to do stuff...
        });

      this.initializingSubscription = this.ccService.initializing$.subscribe(
        (event: NgcInitializingEvent) => {
          // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
          // console.log(`initializing: ${JSON.stringify(event)}`);
        });

      this.initializedSubscription = this.ccService.initialized$.subscribe(
        () => {
          // the cookieconsent has been successfully initialized.
          // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
          // console.log(`initialized: ${JSON.stringify(event)}`);
        });

      this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          // the cookieconsent has failed to initialize... 
          // console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
        });

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // console.log("frve");
          // you can use this.ccService.getConfig() to do stuff...
        });

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          // console.log("frve");
          // you can use this.ccService.getConfig() to do stuff...
        });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // console.log("frve");
          // you can use this.ccService.getConfig() to do stuff...
        });
    // } else {
    //   // console.log('Cookie consent not yet accepted');
    // }

    const journalType = { jnl_type: "JNL" }

    const journalSub = this.journalService.getJournals(journalType).subscribe((result) => {
      // this.sharedService.setJournalId(result.data[0].id);
      this.sharedService.setJournalId("BJS");
      this.mainService.getAllThemeSettings(result.data[0].id).subscribe((result) => {
        if (result.status === 200) {
          this.sharedService.setAllThemsettingsData(result);
          if (isPlatformBrowser(this.platformId)) {
            Object.entries(result.data.theme_colors).map(([key, value]) => {
              const element = "--" + key;
              const val = value as string;
              document.documentElement.style.setProperty(element, val);
            });
          }
        }
      });
    })
    this.subscription.push(journalSub);

  }
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollPosition > this.showScrollHeight) {
      this.isShowScrollTopButton = true;
    } else if (this.isShowScrollTopButton && scrollPosition < this.showScrollHeight) {
      this.isShowScrollTopButton = false;
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  toggleMenu() {
    this.menuVisible = !this.menuVisible;
    const navMenu = document.querySelector('.nav ul');
    if (navMenu) {
      navMenu.classList.toggle('show', this.menuVisible);
    }
  }
  receiveAcademyMenuData(data: any) {
    this.academyMenuData = data;  // Store the data in a variable
  }

}
