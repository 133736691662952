import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppHeaderComponent } from './template/app-header/app-header.component';
import { AppFooterComponent } from './template/app-footer/app-footer.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PartnersComponent } from './template/partners/partners.component';
import { DropdownService } from './services/dropdown.service';
import {  provideHttpClient, withFetch } from '@angular/common/http';
import { ContentComponent } from './content/content.component';
import { HomeModule } from './home/home.module';
import { ArticlesModule } from './articles/articles.module';
import { GeneralModule } from './general/general.module';
import { SearchContextService } from './services/search-context-service';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
      "domain": "bjsaccadmey"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
      "popup": {
        "background": "#F2F2F2",
        "text": "#2a2a2a",
        "link": "#2a2a2a"
      },
      "button": {
        "background": "#121F37",
        "text": "#FFFFFF",
        "border": "#121f37"
      }
  },
  "type": "opt-in",
  "content": {
      "header": "Your choice regarding cookies on this site",
      "message": "This website uses cookies to improve your experience. By continuing to use this site, you are consenting to the use of cookies as set out in our",
      "dismiss": "Got it!",
      "deny": "",
      "link": "cookie policy.",
      "href": "../page/cookies",
      "policy": "Cookie Policy",
      "allow": "Accept + close"
  }
}


@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    PartnersComponent,  
    ContentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,   
    ReactiveFormsModule,
    HomeModule,
    GeneralModule,
    ArticlesModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    provideClientHydration(),
    DropdownService ,
    SearchContextService,
    provideHttpClient(withFetch()) // Configure HttpClient to use fetch
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
